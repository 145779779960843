// Custom styling for the notification
<template>
  <v-row>
    <v-col
      cols="3"
    >
      <v-img src="@/assets/anna_halv.svg"></v-img>
    </v-col>
    <v-col
      cols="9"
    >
      {{ msg }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'Toast',
  props: ['msg']
}
</script>